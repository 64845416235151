import { useAxios } from '@/app/composable';
import { Ref, computed, ref, watch } from '@vue/composition-api';
import { isNil, isEmpty } from 'ramda';
import { TaskAPI } from '../api';
import { Execution, Loop, TaskResultPayload, Workflow } from '../types';

export function useTaskTableView(taskId: string, structure: Ref<any>, pageSize: number, workflow: Ref<Workflow>) {
    const { exec, loading } = useAxios();
    const latestTestExecution: Ref<any> = ref<Execution | null>(null);
    const latestTestExecutionSample = ref<any>(null);
    const totalSampleRows = ref<number>(0);
    const testDataLastUpdated = ref<any>(null);
    const latestTestExecutionSampleDetails = ref<any>(null);
    const latestTestExecutionStructure = ref<any>(null);
    const latestExecutionType = ref<string | null>(null);
    const sourceTask = ref<{ id: string; displayName: string } | null>(null);

    const visibleColumns = ref<any[]>([]);
    const hiddenColumns = ref<any[]>([]);
    const columns = ref<any[]>([]);

    const availableVariables = computed(() => {
        if (isNil(workflow.value.configuration.loops) || isEmpty(workflow.value.configuration.loops)) return {};

        const inLoops = workflow.value.configuration.loops.filter((loop: Loop) => loop.tasks.includes(taskId));
        return inLoops.reduce((acc: Record<string, string>, loop: Loop) => {
            return {
                ...acc,
                ...loop.variables,
            };
        }, {});
    });

    // Loads the short version of task's test result
    const loadLatestExecution = () => {
        exec(TaskAPI.resultDetails(taskId)).then(async (resDetails: any) => {
            latestTestExecutionSample.value = null;
            latestTestExecutionSampleDetails.value = resDetails.data?.sample;
            latestExecutionType.value = resDetails.data?.executionType;
            sourceTask.value = resDetails.data?.sourceTask;
            testDataLastUpdated.value = resDetails.data?.dataLastUpdated;
            latestTestExecutionStructure.value = resDetails.data?.structure || structure.value;
            if (!latestTestExecutionStructure.value) return;

            exec(TaskAPI.resultShort(taskId)).then(async (res: any) => {
                if (!res.data) return;

                // Initialize visible and hidden columns
                visibleColumns.value = [];
                hiddenColumns.value = [];
                columns.value = [];

                // Crop test results according to pagination's page size
                const sampleResults = res.data?.data.slice(0, pageSize);

                latestTestExecutionSample.value =
                    sampleResults.length === 1 && Object.keys(sampleResults[0]).length === 0 ? [] : sampleResults;
                totalSampleRows.value = res.data?.totalRows;

                if (res.data?.data && res.data.data.length) {
                    // Columns included in the short result
                    const dataColumns = Object.keys(res.data.data[0]);

                    // All columns in the test result data
                    const structureColumns = Object.keys(latestTestExecutionStructure.value);
                    // Split columns into visible and hidden
                    dataColumns.forEach((column: string) => {
                        if (latestTestExecutionStructure.value[column])
                            visibleColumns.value.push({
                                label: column,
                                value: column,
                                type: latestTestExecutionStructure.value[column].toLowerCase(),
                                isVariable: false,
                            });
                        if (availableVariables.value[column])
                            visibleColumns.value.push({
                                label: column,
                                value: column,
                                type: availableVariables.value[column].toLowerCase(),
                                isVariable: true,
                            });
                    });

                    structureColumns.forEach((column: string) => {
                        const obj = {
                            label: column,
                            value: column,
                            type: latestTestExecutionStructure.value[column].toLowerCase(),
                        };
                        columns.value.push(obj);
                        if (!dataColumns.includes(column)) {
                            hiddenColumns.value.push(obj);
                        }
                    });
                }
            });
        });
    };

    const getResult = async (data: TaskResultPayload) => {
        exec(TaskAPI.result(taskId, data)).then(async (res: any) => {
            if (!res) return;
            latestTestExecutionSample.value = res.data?.data;
            totalSampleRows.value = res.data?.totalRows;
        });
    };

    watch(
        () => structure.value,
        () => loadLatestExecution(),
        { immediate: true, deep: true },
    );

    return {
        latestTestExecution,
        latestTestExecutionSample,
        testDataLastUpdated,
        latestTestExecutionSampleDetails,
        latestTestExecutionStructure,
        latestExecutionType,
        sourceTask,
        loading,
        visibleColumns,
        hiddenColumns,
        totalSampleRows,
        getResult,
        refetch: loadLatestExecution,
        structureColumns: columns,
    };
}
