import { useAxios } from '@/app/composable';
import store from '@/app/store';
import { AssetTypeId, StatusCode } from '@/modules/asset/constants';
import { SearchAPI } from '@/modules/search/api';
import { Ref, computed, ref } from '@vue/composition-api';

export function useAssets(
    root: any,
    noOfItems: Ref<number>,
    id: Ref<string | null> = ref<string | null>(null),
    excludeIds: Ref<string[] | number[]> = ref<string[] | number[]>([]),
) {
    const { exec } = useAxios(true);

    const datasets = ref<any>([]);
    const results = ref<any>([]);
    const totalDatasets = ref<number>(0);
    const totalResults = ref<number>(0);

    const user = computed(() => store.state.auth.user);

    const searchQuery = {
        assetTypeId: 0,
        query: {
            input: { text: '*' },
            settings: { caseSensitive: false, partialMatch: true },
            ids: [],
            excludeIds: [],
        },
        facets: { status: [StatusCode.Available] },
        filters: {},
        pagination: { page: 1, size: noOfItems.value },
        sortBy: { field: 'relevance', asc: false },
        settings: { own: true, organisationId: user.value.organisationId },
    };

    const setSearchQuery = (searchText: string, page: number, assetTypeId: number) => {
        searchQuery.assetTypeId = assetTypeId;
        searchQuery.query.ids = id.value ? [id.value] : ([] as any);
        searchQuery.query.input.text = searchText ? searchText : '*';
        searchQuery.pagination.page = page;
        searchQuery.query.excludeIds = excludeIds.value as any;
        if (assetTypeId === AssetTypeId.Dataset)
            searchQuery.filters = { isLocal: false, accessibility: ['Through an API'] };
        else if (assetTypeId === AssetTypeId.Result) searchQuery.filters = { isLocal: false };
    };

    const refetchDatasets = (searchText: string, page: number): Promise<void> => {
        return new Promise((resolve) => {
            setSearchQuery(searchText, page, AssetTypeId.Dataset);
            exec(SearchAPI.myAssetsFacetedSearch(searchQuery))
                .then((res: any) => {
                    datasets.value = res.data?.results.map((asset: any) => ({ ...asset, id: asset.id.toString() }));
                    totalDatasets.value = res.data?.total;
                    resolve();
                })
                .catch(() => {
                    (root as any).$toastr.e('Error while trying to retrieve datasets', 'Error');
                    resolve();
                });
        });
    };

    const refetchResults = (searchText: string, page: number): Promise<void> => {
        return new Promise((resolve) => {
            setSearchQuery(searchText, page, AssetTypeId.Result);
            exec(SearchAPI.myAssetsFacetedSearch(searchQuery))
                .then((res: any) => {
                    results.value = res.data?.results.map((asset: any) => ({ ...asset, id: asset.id.toString() }));
                    totalResults.value = res.data?.total;
                    resolve();
                })
                .catch(() => {
                    (root as any).$toastr.e('Error while trying to retrieve results', 'Error');
                    resolve();
                });
        });
    };

    refetchDatasets('', 1);
    refetchResults('', 1);

    return { datasets, results, totalDatasets, totalResults, refetchDatasets, refetchResults };
}
