























































































































































import { FormBlock } from '@/app/components';
import { useApolloBlock } from '@/modules/apollo/composable';
import { StepControl } from '@/modules/apollo/constants';
import { AlertMessage, Step } from '@/modules/apollo/interfaces';
import { useAxios } from '@/app/composable';
import { computed, defineComponent, PropType, Ref, ref } from '@vue/composition-api';
import * as R from 'ramda';
import { extend, ValidationProvider } from 'vee-validate';
import TaskConfigurationWizard from '../components/TaskConfigurationWizard.vue';
// import { AlertType } from '@/app/constants';
import router from '@/app/router';
import { maxLengthValidator, requiredValidator } from '@/app/validators';
import { ScheduleAPI } from '../../workflow-designer/api';
import { ApolloAPI } from '../api';
import Schedule from '../components/Schedule.vue';
import { WorkflowStatus } from '../constants/workflow-status.constants';
import { JobBlock, JobTask, JobWorkflow } from '../interfaces';

extend('max', maxLengthValidator);

extend('required', requiredValidator);

export default defineComponent({
    name: 'Loader',
    props: {
        workflow: {
            type: Object as PropType<JobWorkflow>,
            required: true,
        },
        task: {
            type: Object as PropType<JobTask>,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        block: {
            type: Object as PropType<JobBlock>,
            required: true,
        },
    },
    components: { TaskConfigurationWizard, FormBlock, ValidationProvider, Schedule },
    setup(props, { root }) {
        const { exec } = useAxios(true); // loading, error
        const editingSchedule = ref<boolean>(false);
        const closeScheduleEditor = ref<boolean>(false);
        const schedulesError = ref<string | null>(null);
        const loadingSchedules = ref<boolean>(false);
        const schedules = ref<any>([]);
        const stepIndex = ref<number>(0);
        const steps: Ref<Step[]> = computed(() => {
            return [
                {
                    title: 'Setup Loader',
                    header: `Configure ${props.task.displayName}`,
                    controls: [StepControl.Cancel, StepControl.Finalize],
                    active: true,
                },
            ];
        });
        const isLocal = computed(() => props.workflow.platform === 'docker');
        const hasSchedules = computed(() => {
            return !!props.workflow.tasks.filter((task: any) => task.block.id === 'dc.ApiHarvester').length;
        });
        const alerts: Ref<AlertMessage[]> = computed((): AlertMessage[] => {
            return [];
        });
        const step = computed(() => steps.value[stepIndex.value]);
        const isReady = computed(() => props.workflow.status === WorkflowStatus.Ready);
        const dirty = computed(() => R.equals(props.task.configuration, configuration));

        const getSchedules = () => {
            loadingSchedules.value = true;
            ScheduleAPI.getSchedules(props.workflow.id).then((resSchedules: any) => {
                schedules.value = resSchedules.data;
                if (schedules.value.length) {
                    closeScheduleEditor.value = true;
                }
                loadingSchedules.value = false;
            });
        };

        const { parameters, hasParameter, getName, getDescription, getPossibleValues } = useApolloBlock(props.block);

        const configuration = ref({ name: null, description: null, storageLocation: 'cloud', outputPath: null }); // name, description and outputPath should be used for the asset creation

        const saveSchedule = async (schedule: any) => {
            schedulesError.value = null;
            if (schedule.id) {
                ScheduleAPI.update(schedule.id, schedule)
                    .then(() => {
                        getSchedules();
                        (root as any).$toastr.s('Schedule has been updated successfully', 'Success');
                    })
                    .catch((e) => {
                        (root as any).$toastr.e(e.response.data.message, 'Error');
                        schedulesError.value = e.response.data.message;
                    });
            } else {
                ScheduleAPI.create(schedule)
                    .then(() => {
                        (root as any).$toastr.s('Schedule has been created successfully', 'Success');
                        getSchedules();
                    })
                    .catch((e) => {
                        (root as any).$toastr.e(e.response.data.message, 'Error');
                        schedulesError.value = e.response.data.message;
                    });
            }
        };

        const changeScheduleStatus = (schedule: any) => {
            const idx = schedules.value.findIndex((obj: any) => obj.id === schedule.id);
            if (schedule.isEnabled) {
                ScheduleAPI.deactivate(schedule.id).then(() => {
                    schedules.value[idx].isEnabled = false;
                });
            } else {
                ScheduleAPI.activate(schedule.id).then(() => {
                    schedules.value[idx].isEnabled = true;
                });
            }
        };

        const back = () => {
            router.push({ name: 'apollo:jobs', params: { path: '/apollo/jobs' } });
        };

        const deleteSchedule = (id: string) => {
            ScheduleAPI.delete(id).then(() => {
                const idx = schedules.value.findIndex((obj: any) => obj.id === id);
                schedules.value.splice(idx, 1);
            });
        };

        const finalize = () => {
            /**
             * the steps for this will be: finalize workflow, create asset and then re-finalize workflow
             */
            const assetId = 1; // #TODO use the created asset id
            let assetIds: any = [];

            if (props.workflow.configuration.assetIds) {
                assetIds = props.workflow.configuration.assetIds
                    ? props.workflow.configuration.assetIds.concat([assetId])
                    : [assetId];
            }

            exec(
                ApolloAPI.finalise(props.workflow.id, {
                    assetIds: assetIds,
                    storageLocation: configuration.value.storageLocation,
                }),
            )
                .then(async () => {
                    (root as any).$toastr.s('Loader has finalised successfully', 'Success');
                    back();
                })
                .catch(() => {
                    (root as any).$toastr.s('Loader has failed to be finalised due to an error', 'Error');
                });
        };

        getSchedules();

        return {
            steps,
            step,
            stepIndex,
            alerts,
            parameters,
            hasParameter,
            getName,
            getDescription,
            getPossibleValues,
            configuration,
            closeScheduleEditor,
            schedules,
            changeScheduleStatus,
            deleteSchedule,
            editingSchedule,
            schedulesError,
            loadingSchedules,
            back,
            saveSchedule,
            hasSchedules,
            finalize,
            isReady,
            isLocal,
            dirty,
        };
    },
});
