




























































































































































































































































































































import { computed, defineComponent, PropType, reactive, ref, Ref } from '@vue/composition-api';
import { Card, FormBlock, JsonParser, TwProgressBar } from '@/app/components';
import { useApolloBlock } from '@/modules/apollo/composable';
import { extend, ValidationProvider } from 'vee-validate';
import { ExclamationIcon } from '@vue-hero-icons/solid';
import { DocumentIcon } from '@vue-hero-icons/outline';
import { TFileDropzone } from '@/app/components/common';
import { AlertType } from '@/app/constants';
import { FileHarvesterConfiguration } from '@/modules/apollo/interfaces/harvesters';
import { AlertMessage, JobBlock, JobTask, JobWorkflow, Step } from '@/modules/apollo/interfaces';
import { StepControl } from '@/modules/apollo/constants';
import TaskConfigurationWizard from '../TaskConfigurationWizard.vue';
import { requiredValidator } from '@/app/validators';
import * as R from 'ramda';
import { useAxios, useFilters } from '@/app/composable';
import { ApolloAPI, UploadAPI } from '@/modules/apollo/api';
import { useFileHarvester } from '@/modules/apollo/composable/harvesters';

extend('required', requiredValidator);

export default defineComponent({
    name: 'FileHarvester',
    props: {
        workflow: {
            type: Object as PropType<JobWorkflow>,
            required: true,
        },
        task: {
            type: Object as PropType<JobTask>,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        block: {
            type: Object as PropType<JobBlock>,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Card,
        FormBlock,
        JsonParser,
        DocumentIcon,
        TwProgressBar,
        TFileDropzone,
        ExclamationIcon,
        ValidationProvider,
        TaskConfigurationWizard,
    },
    setup(props, { emit, root }) {
        const { exec } = useAxios(true);
        const { formatBytes } = useFilters();

        const configuration: FileHarvesterConfiguration = reactive(R.clone(props.task.configuration));

        const stepIndex = ref<number>(0);
        const alerts = ref<AlertMessage[]>([]);
        const steps: Ref<Step[]> = computed(() => {
            return [
                {
                    title: 'Setup Harvester Service',
                    header: `Configure ${props.task.displayName}`,
                    controls: [StepControl.Cancel],
                    active: true,
                    canGoNext: () =>
                        (!requireSampleUpload.value || configuration.sample?.value) &&
                        configuration.files.value &&
                        configuration.files.value.length > 0,
                },
                {
                    title: 'Test and Review Configuration',
                    header: `Review ${props.task.displayName}`,
                    controls: [StepControl.Save, StepControl.Cancel],
                    active: true,
                },
            ];
        });

        // Sample related fields
        const newSampleFile = ref<File | undefined>();
        const newParsedSampleFile = ref<{ data: any; meta: { fields: string[] }; cropped: boolean } | undefined>();
        const sampleValidationErrors = ref<string[]>([]);
        const forceClearSample = ref<Date | null>(null);
        const sampleValidationPercentage = ref<number | null>(null);
        const sampleFileName = computed(() => (newSampleFile.value ? newSampleFile.value.name : null));

        // File related fields
        const newFiles: Ref<File[]> = ref<File[]>([]);
        const filesValidationPercentage: Ref<number | null> = ref<number | null>(null);
        const filesValidationErrors: Ref<string[]> = ref<string[]>([]);
        const forceClearFiles: Ref<Date | null> = ref<Date | null>(null);
        const filesUploadPercentage: Ref<number | null> = ref<number | null>(null);
        const fileInUpload: Ref<number | null> = ref<number | null>(null);
        const filesFileNames = computed(() => newFiles.value.map((file: File) => file.name).join(','));

        const dirty = computed(() => JSON.stringify(props.task.configuration) !== JSON.stringify(configuration));
        const fileType = computed({
            get: () => configuration.fileType.value,
            set: (newFileType: string) => (configuration.fileType = { value: newFileType }),
        });
        const requireSampleUpload = computed(() => fileType.value !== 'other');
        const existingFiles = computed(
            () =>
                !R.isNil(props.task.configuration.sample) &&
                !R.isNil(props.task.configuration.sample.value) &&
                !R.isNil(props.task.configuration.sample.value.filename.value),
        );

        const showSampleDropzone = computed(() => {
            if (!requireSampleUpload.value) return false;
            return (
                (!props.task.configuration.sample?.value || !props.task.configuration.sample.value.filename.value) &&
                (!configuration.sample?.value || !configuration.sample.value.filename.value) &&
                sampleValidationErrors.value.length === 0 &&
                !sampleValidationPercentage.value
            );
        });

        const { acceptedFiles, fileTypePath, validate, fileTypeCropSize, warnIfCropped, renderAs } = useFileHarvester(
            fileType,
        );
        const { parameters, hasParameter, getName, getDescription, getPossibleValues } = useApolloBlock(props.block);

        // Functions
        const showSampleCroppedWarning = () => {
            alerts.value = [
                {
                    id: 'sample-cropped',
                    message:
                        'Inconsistencies may appear between the Sample and the File, because the Sample has been cropped.',
                    type: AlertType.Warn,
                    visible: true,
                },
            ];
        };

        const clearSampleCroppedWarning = () => {
            alerts.value = [];
        };

        const sampleUploaded = async (files: File[]) => {
            if (files.length > 0) {
                validate(files[0], sampleValidationPercentage, null, fileTypeCropSize.value)
                    .then(({ data, meta, cropped }) => {
                        newSampleFile.value = files[0];
                        if (cropped && warnIfCropped.value) {
                            showSampleCroppedWarning();
                        }
                        newParsedSampleFile.value = { data, meta, cropped };
                        sampleValidationErrors.value = [];
                        configuration.sample = {
                            value: {
                                bucket: { value: null },
                                path: { value: null },
                                filename: { value: files[0].name },
                                size: { value: files[0].size },
                                fields: { value: newParsedSampleFile.value.meta.fields },
                            },
                        };

                        // revalidate if files exist
                        if (newFiles.value.length > 0) {
                            filesUploaded(newFiles.value);
                        }
                    })
                    .catch((errors: any) => {
                        sampleValidationErrors.value = errors;
                    })
                    .finally(() => (sampleValidationPercentage.value = null));
            } else {
                clearSample();
            }
        };

        const filesUploaded = async (files: File[]) => {
            if (files.length > 0) {
                // fail on duplicate name
                const fileNames: string[] = configuration.files.value.map((file: any) => file.filename.value);
                if (fileNames.includes(files[0].name)) {
                    filesValidationErrors.value = [
                        `File with name "${files[0].name}" already exists and cannot be uploaded again!`,
                    ];
                    return;
                }
                // Assume one file for now (to be changed)
                validate(
                    files[0],
                    filesValidationPercentage,
                    configuration.sample?.value ? configuration.sample.value.fields.value : null,
                    fileTypeCropSize.value,
                    newParsedSampleFile.value ? newParsedSampleFile.value.cropped : false,
                )
                    .then(() => {
                        newFiles.value = [files[0]];
                        filesValidationErrors.value = [];
                        configuration.files = {
                            value: [
                                ...configuration.files.value,
                                ...(files.map((f: File) => {
                                    return {
                                        bucket: { value: null },
                                        path: { value: fileTypePath.value },
                                        filename: { value: f.name },
                                        size: { value: f.size },
                                    };
                                }) as {
                                    bucket: { value: string | null };
                                    path: { value: string };
                                    filename: { value: string };
                                    size?: { value: number };
                                }[]),
                            ],
                        };
                    })
                    .catch((errors: any) => {
                        filesValidationErrors.value = errors;
                    })
                    .finally(() => (filesValidationPercentage.value = null));
            } else {
                clearFiles();
            }
        };

        const clearSample = () => {
            sampleValidationErrors.value = [];
            newSampleFile.value = undefined;
            newParsedSampleFile.value = undefined;
            forceClearSample.value = new Date();
            clearSampleCroppedWarning();
            configuration.sample = { value: props.task.configuration.sample.value };
        };

        const clearFiles = () => {
            filesValidationErrors.value = [];
            forceClearFiles.value = new Date();
            newFiles.value = [];
            configuration.files = { value: props.task.configuration.files.value };
        };

        const clearSampleAndFiles = () => {
            if (requireSampleUpload.value) {
                clearSample();
            }
            clearFiles();
        };

        const save = async () => {
            emit('loading', true);
            await exec(ApolloAPI.updateTask(props.task.id, { configuration }));
            const response = await exec(UploadAPI.getPolicy(props.workflow.id));
            const policy = response?.data;
            if (newSampleFile) {
                await exec(UploadAPI.upload(newSampleFile.value, `sample.${fileType.value}`, policy));
            }
            if (newFiles.value.length > 0) {
                // uploading.value = true;
                for (let i = 0; i < newFiles.value.length; i += 1) {
                    filesUploadPercentage.value = 0;
                    fileInUpload.value = i;
                    const file: File = newFiles.value[i];
                    await exec(
                        UploadAPI.upload(
                            file,
                            `${file?.name}`,
                            policy,
                            (progressEvent: { total: number; loaded: number }) => {
                                filesUploadPercentage.value = Math.round(
                                    (progressEvent.loaded * 100) / progressEvent.total,
                                );
                            },
                        ),
                    );
                    const filesIndex = configuration.files.value.length - newFiles.value.length + i;
                    if (!props.workflow.runnerId) {
                        configuration.files.value[filesIndex].path.value = 'upload';
                    }
                    configuration.files.value[filesIndex].bucket.value = policy.formData.bucket;
                }
            }
            filesUploadPercentage.value = null;
            fileInUpload.value = null;
            await exec(ApolloAPI.updateTask(props.task.id, { configuration }));

            emit('loading', false);
            emit('refetch');

            stepIndex.value = 0;
            clearSampleAndFiles();
            alerts.value.push({
                id: 'files-uploaded',
                message: 'Harvester configuration saved successfully',
                type: AlertType.Info,
                visible: true,
            });
        };

        const goBack = () => {
            root.$router.push({ name: 'apollo:jobs' });
        };

        return {
            // sample
            sampleFileName,
            forceClearSample,
            showSampleDropzone,
            newParsedSampleFile,
            sampleValidationErrors,
            sampleValidationPercentage,
            clearSample,
            sampleUploaded,

            // files
            newFiles,
            fileInUpload,
            filesFileNames,
            forceClearFiles,
            filesValidationErrors,
            filesUploadPercentage,
            filesValidationPercentage,
            clearFiles,
            filesUploaded,
            clearSampleAndFiles,

            // generic
            steps,
            dirty,
            alerts,
            fileType,
            renderAs,
            stepIndex,
            parameters,
            acceptedFiles,
            configuration,
            existingFiles,
            requireSampleUpload,
            save,
            goBack,
            getName,
            formatBytes,
            hasParameter,
            getDescription,
            getPossibleValues,
        };
    },
});
