import { useAxios, useFeatureFlags, useQueryParams } from '@/app/composable';
import { useRouter } from '@/app/composable/router';
import store from '@/app/store';
import { computed, ref, Ref } from '@vue/composition-api';
import Fuse from 'fuse.js';
import * as R from 'ramda';
import { AlertsAPI } from '../api';
import { AlertEntityType, AlertEventType, AlertSortingOption, AlertSourceType, QualityMetrics } from '../constants';
import { Alert } from '../interfaces';

export function useAlerts(root: any, type: string[] = []) {
    const alerts: any = computed(() => store.state.alertEngine.alerts);
    const ALERTS_MAX_NUM = 25;
    const router = useRouter();
    const { get, set } = useQueryParams(root, router, 'alerts');
    const { exec, loading, error } = useAxios(true);
    const { flag } = useFeatureFlags();
    const isAlertsEnabled = flag('alerts');

    const total: Ref<number> = computed(() => alerts.value.length);

    const defaultSortBy = AlertSortingOption.DATE_CREATED_DESC.label;
    const query = ref({
        text: get('search') ? get('search') : '',
        sortBy: get('sortBy') ? get('sortBy') : defaultSortBy,
    });

    const sortBy = ref(get('sortBy') ? get('sortBy') : defaultSortBy);
    const sortByObject: any = ref(AlertSortingOption.findByLabel(sortBy.value));
    const sortedAlerts = computed(() => {
        if (sortByObject.value.field) {
            const sort = sortByObject.value.asc
                ? R.ascend(R.prop(sortByObject.value.field))
                : R.descend(R.prop(sortByObject.value.field));
            return R.sortWith([sort])(alerts.value);
        }
        return alerts.value;
    });

    const sortByChanged = (label: string) => {
        if (!sortBy.value) sortBy.value = label;
        set('sortBy', label, defaultSortBy);
        sortByObject.value = AlertSortingOption.findByLabel(label);
        query.value.sortBy = { field: sortByObject.field, asc: sortByObject.asc };
    };

    const searchedAlerts = computed(() => {
        set('search', query.value.text, '');
        if (!R.trim(query.value.text)) return sortedAlerts.value;

        const fuseSearch = new Fuse(sortedAlerts.value, {
            keys: ['entityId', 'name', 'entities.id', 'entities.name', 'entities.workflowId'],
            threshold: 0.1,
            ignoreLocation: true,
        });
        const searched = fuseSearch.search(query.value.text);
        return searched.map((searchedItem: any) => searchedItem.item);
    });

    const fetchAlerts = () => {
        if (isAlertsEnabled.value) store.dispatch.alertEngine.fetchAlerts();
    };
    const alertsLoading = computed<boolean>(() => store.getters.alertEngine.getAlertsLoading());
    const deleteAlert = (id: string) => {
        exec(AlertsAPI.deleteAlert(id))
            .then(() => {
                store.dispatch.alertEngine.deleteAlert(id);
                (root as any).$toastr.s('Alert deleted successfuly', 'Success');
            })
            .catch(() => {
                (root as any).$toastr.e('Deleting Alert failed', 'Error');
            });
    };

    const toggleAlert = (id: string) => {
        const alertToChangeStatus = alerts.value.find((alert: any) => alert.id === id);
        if (alertToChangeStatus?.isEnabled) {
            exec(AlertsAPI.deactivateAlert(id)).then(() => {
                store.dispatch.alertEngine.deactivateAlert(id);
            });
        } else {
            exec(AlertsAPI.activateAlert(id)).then(() => {
                store.dispatch.alertEngine.activateAlert(id);
            });
        }
    };

    const save = (payload: Alert) => {
        if (payload.id) {
            exec(AlertsAPI.updateAlert(payload.id, payload))
                .then(() => {
                    (root as any).$toastr.s('Alert updated successfuly', 'Success');
                    root.$router.push({ name: 'alerts', query: { tab: 1 } });
                })
                .catch(() => {
                    (root as any).$toastr.e('Updating Alert failed', 'Error');
                });
        } else {
            exec(AlertsAPI.createAlert(payload))
                .then(() => {
                    (root as any).$toastr.s('Alert created successfuly', 'Success');
                    root.$router.push({ name: 'alerts', query: { tab: 1 } });
                })
                .catch(() => {
                    (root as any).$toastr.e('Creating Alert failed', 'Error');
                });
        }
    };

    const getSourceTypeFromEventType = (eventType: AlertEventType | null) => {
        switch (eventType) {
            case AlertEventType.DatasetTimeliness:
            case AlertEventType.DatasetCompleteness:
            case AlertEventType.DatasetQualityImprovements:
                return AlertSourceType.Dataset;
            case AlertEventType.ResultTimeliness:
            case AlertEventType.ResultCompleteness:
                return AlertSourceType.Result;
            case AlertEventType.DataCheckinFailed:
                return AlertSourceType.DataCheckin;
            case AlertEventType.AnalyticsFailed:
            case AlertEventType.AnalyticsSkipped:
            case AlertEventType.AnalyticsTaskFailed:
                return AlertSourceType.Analytics;
            default:
                return null;
        }
    };

    const specificTypeAlerts = computed<{ entityId: string | number; alertId: string; enabled: boolean }[]>(() => {
        if (type.length) return store.getters.alertEngine.getSourceTypeAlerts(type);
        return [];
    });

    const checkAlert = (
        id: number,
    ): { entityId: string | number; alertId: string | number; enabled: boolean } | undefined => {
        if (type.length) {
            return specificTypeAlerts.value.find(
                (specificTypeAlert: { entityId: string | number; alertId: string; enabled: boolean }) =>
                    specificTypeAlert.entityId == id,
            );
        }

        return undefined;
    };

    const getQualityMetricFromEventType = (eventType: AlertEventType) => {
        switch (eventType) {
            case AlertEventType.DatasetTimeliness:
            case AlertEventType.ResultTimeliness:
                return QualityMetrics.Timeliness;
            case AlertEventType.DatasetCompleteness:
            case AlertEventType.ResultCompleteness:
                return QualityMetrics.Completeness;
            case AlertEventType.DatasetQualityImprovements:
                return QualityMetrics.QualityImprovenents;
            default:
                return '';
        }
    };

    const getEntityTypeFromSourceType = (sourceType: AlertSourceType | null) => {
        switch (sourceType) {
            case AlertSourceType.DataCheckin:
            case AlertSourceType.Analytics:
                return AlertEntityType.Workflow;
            case AlertSourceType.Dataset:
            case AlertSourceType.Result:
                return AlertEntityType.Asset;
            default:
                return null;
        }
    };

    return {
        deleteAlert,
        toggleAlert,
        loading,
        error,
        total,
        ALERTS_MAX_NUM,
        save,
        searchedAlerts,
        sortByChanged,
        sortBy,
        query,
        sortByObject,
        alerts,
        getSourceTypeFromEventType,
        getQualityMetricFromEventType,
        fetchAlerts,
        specificTypeAlerts,
        checkAlert,
        getEntityTypeFromSourceType,
        alertsLoading,
    };
}
