import { useAxios } from '@/app/composable';
import { AssetTypeId, StatusCode } from '@/modules/asset/constants';
import { SearchAPI } from '@/modules/search/api';
import { Ref, ref } from '@vue/composition-api';

export function useResultAssetParameter(
    runnerId: Ref<any> | null,
    root: any,
    noOfItems: Ref<number>,
    id: Ref<string | null> = ref<string | null>(null),
    excludeIds: Ref<string[] | number[]> = ref<string[] | number[]>([]),
    statuses: string[] = [StatusCode.Incomplete, StatusCode.Internal, StatusCode.Pending, StatusCode.Available],
) {
    const { exec, loading } = useAxios(true);
    const assets = ref<any>([]);
    const totalItems = ref<number>(0);
    const totalAssets = ref<number>(0);

    const searchQuery = {
        assetTypeId: AssetTypeId.Result,
        query: {
            input: { text: '*' },
            settings: { caseSensitive: false, partialMatch: true },
            ids: [],
            excludeIds: [],
        },
        facets: { status: statuses },
        filters: {},
        pagination: { page: 1, size: noOfItems.value },
        sortBy: { field: 'relevance', asc: false },
        settings: {},
    };

    const refetch = (searchText: string, page: number): Promise<void> => {
        return new Promise((resolve) => {
            searchQuery.query.ids = id.value ? [id.value] : ([] as any);
            searchQuery.query.input.text = searchText ? searchText : '*';
            searchQuery.pagination.page = page;
            searchQuery.query.excludeIds = excludeIds.value as any;
            searchQuery.filters = runnerId?.value ? { runnerId: runnerId.value } : { isLocal: false };

            const api = runnerId?.value ? SearchAPI.assetsSearchUseOnPremise : SearchAPI.assetsSearchUseInCloud;

            exec(api(searchQuery))
                .then((res: any) => {
                    assets.value = res.data?.results.map((asset: any) => {
                        return { ...asset, id: asset.id.toString() };
                    });
                    totalItems.value = res.data?.total;
                    resolve();
                })
                .catch(() => {
                    (root as any).$toastr.e('Error while trying to retrieve results', 'Error');
                    resolve();
                });
        });
    };

    refetch('', 1).then(() => (totalAssets.value = totalItems.value));

    return { assets, loading, totalItems, totalAssets, refetch };
}
