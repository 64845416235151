import store from '@/app/store';
import { S } from '@/app/utilities';
import { WorkflowStatus } from '@/modules/apollo/constants';
import { StatusCode } from '@/modules/asset/constants';
import { Asset } from '@/modules/asset/types';
import { useAxios } from '@/app/composable';
import { computed, Ref, ref, watch } from '@vue/composition-api';
import compareVersions from 'compare-versions';
import * as R from 'ramda';
import { TopologicalSort } from 'topological-sort';
import { TaskAPI, WorkflowAPI } from '../api';
import {
    BlockCategory,
    BlockCategoryWrapper,
    BlockType,
    ExecutionStatus,
    ExecutionStatusWrapper,
    ExecutionType,
    ExecutionTypeWrapper,
    MessageType,
} from '../constants';
import {
    DagLoop,
    EventMessage,
    Execution,
    ExecutionLog,
    Loop,
    Pipeline,
    RunningExecution,
    Task,
    Workflow,
} from '../types';
import { WorkflowExecution } from '../types/workflow-executions.interface';
import { useValidator } from './validator';
import { useAvailableWorkflowModels } from './available-workflow-models';
import { useExecutionLogs } from './execution-logs';

export function useWorkflowDesigner(workflowId: string, root: any, queryParams = '{}') {
    const axiosRunner = useAxios(true);
    const workflow = ref<any>(null);
    const pipelines = ref<Pipeline[]>([]);
    const dagLoops = ref<DagLoop[]>([]);
    const loops = ref<Loop[]>([]);
    const visualisations = ref<{ id: string; taskId: string }[] | null>(null);
    const taskMap = ref<Map<string, Task>>(new Map());

    const runningExecution: Ref<RunningExecution | null> = ref<RunningExecution | null>(null);
    const otherRunningExecutions = ref<RunningExecution[]>([]);
    const pendingExecutions: Ref<WorkflowExecution[]> = ref<WorkflowExecution[]>([]);
    const failedExecutions: Ref<WorkflowExecution[]> = ref<WorkflowExecution[]>([]);
    const blockedExecutions: Ref<WorkflowExecution[]> = ref<WorkflowExecution[]>([]);
    const resultAssets: Ref<Asset[]> = ref<Asset[]>([]);
    const blocksNotSupportedInNewVersion = ref<string[]>([]);
    const blocksUpgradedInNewVersion = ref<string[]>([]);
    const showUpgradeConfirmation = ref<boolean>(false);
    const executionLogsPerExecution: Ref<Record<string, ExecutionLog[]>> = ref<Record<string, ExecutionLog[]>>({});
    const { invalidTaskIds, blockedTaskIds, runValidation, validationErrors, isLocked, workflowDags } = useValidator(
        workflow,
        taskMap,
        (root as any).$toastr,
    );
    const { refetch: refetchModels } = useAvailableWorkflowModels(workflowId);
    const newLogMessageArrived = ref<ExecutionLog | null>(null);

    const isFinalised = computed(
        () => workflow.value?.status === WorkflowStatus.Ready || workflow.value?.status === WorkflowStatus.Suspended,
    );
    const isDeprecated = computed(() => workflow.value?.status === WorkflowStatus.Deprecated);
    const canBeReopened = computed(
        () =>
            isFinalised.value &&
            resultAssets.value.filter((asset: Asset) =>
                [StatusCode.Uploading, StatusCode.Incomplete, StatusCode.Available].includes(asset.status),
            ).length === resultAssets.value.length,
    );
    const executionKeyCalculator = (type: ExecutionType, task?: Task) =>
        !R.isNil(task) ? `${type}_${task.id}` : `${type}_workflow`;

    const openExecutionsIds = computed(() => {
        const pendingExecutionIds = pendingExecutions.value.map(
            (pendingExecution: { type: ExecutionType; task?: Task | null }) =>
                executionKeyCalculator(
                    pendingExecution.type,
                    R.isNil(pendingExecution.task) ? undefined : pendingExecution.task,
                ),
        );
        return R.isNil(runningExecution.value)
            ? pendingExecutionIds
            : [
                  executionKeyCalculator(runningExecution.value.type, runningExecution.value.task),
                  ...pendingExecutionIds,
              ];
    });

    const executeRun = (request: any): Promise<Execution> => {
        return new Promise((resolve, reject) => {
            request
                .then((response: any) => {
                    resolve(response.data);
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    };

    const runExecution = async (type: ExecutionType, task?: Task) => {
        runningExecution.value = {
            task,
            type,
            status: ExecutionStatus.Queued,
        };
        try {
            const result = await executeRun(
                axiosRunner
                    .exec(
                        WorkflowAPI.run(
                            workflowId,
                            type,
                            task ? task.id : null,
                            workflow.value.imageVersion || 'latest',
                            workflow.value.configuration.sample,
                        ),
                    )
                    .catch(),
            );
            // if this is execution on specific task then add
            // execution to task lazily
            if (task) {
                const updatedTask = taskMap.value.get(task.id);
                if (updatedTask) {
                    updatedTask.executions.unshift(result);
                    taskMap.value.set(task.id, updatedTask);
                }
            }
            if (runningExecution.value)
                runningExecution.value = {
                    ...runningExecution.value,
                    executionId: result.id,
                    createdAt: result.createdAt,
                };
        } catch (error) {
            if (runningExecution.value) {
                const typeWrapper = ExecutionTypeWrapper.find(type);
                if (typeWrapper) {
                    runningExecution.value = null;
                    const { message, category } = typeWrapper.message(
                        ExecutionStatus.Failed,
                        task,
                        (error as any).response.data.message,
                    );
                    (root as any).$toastr.e(message, category);
                }
            }
        }
    };

    const queueExecution = async (type: ExecutionType, task?: Task) => {
        // avoid queuing execution if it already exists
        if (openExecutionsIds.value.some((id: any) => id === executionKeyCalculator(type, task))) return;

        if (R.isNil(runningExecution.value)) {
            await runExecution(type, task);
        } else if (R.isNil(task)) {
            pendingExecutions.value.push({
                type,
            });
        } else {
            pendingExecutions.value.push({
                type,
                task,
            });
        }
    };

    // Find currently running task
    const setCurrentlyRunningExecution = () => {
        if (!R.isNil(tasks.value) && tasks.value.length > 0) {
            const queuedWorkflow = workflow.value.executions.reduce((acc: RunningExecution[], execution: Execution) => {
                if (execution.status === ExecutionStatus.Queued) {
                    acc.push({
                        executionId: execution.id,
                        type: execution.type,
                        status: execution.status,
                        createdAt: new Date(execution.createdAt),
                    });
                }
                return acc;
            }, []);

            const runningWorkflow = workflow.value.executions.reduce(
                (acc: RunningExecution[], execution: Execution) => {
                    if (execution.status === ExecutionStatus.Running) {
                        acc.push({
                            executionId: execution.id,
                            type: execution.type,
                            status: execution.status,
                            createdAt: new Date(execution.createdAt),
                        });
                    }
                    return acc;
                },
                [],
            );
            const queuedTasks = tasks.value.reduce((acc: RunningExecution[], task: Task) => {
                const queuedExecutions = task.executions.filter(
                    (execution: any) => execution.status === ExecutionStatus.Queued,
                );
                for (let qE = 0; qE < queuedExecutions.length; qE++) {
                    const queuedExecution = queuedExecutions[qE];
                    acc.push({
                        task,
                        executionId: queuedExecution.id,
                        type: queuedExecution.type,
                        status: queuedExecution.status,
                        createdAt: new Date(queuedExecution.createdAt),
                    });
                }
                return acc;
            }, []);
            const runningTasks = tasks.value.reduce((acc: RunningExecution[], task: Task) => {
                const queuedExecutions = task.executions.filter(
                    (execution: any) => execution.status === ExecutionStatus.Running,
                );
                for (let qE = 0; qE < queuedExecutions.length; qE++) {
                    const queuedExecution = queuedExecutions[qE];
                    acc.push({
                        task,
                        executionId: queuedExecution.id,
                        type: queuedExecution.type,
                        status: queuedExecution.status,
                        createdAt: new Date(queuedExecution.createdAt),
                    });
                }
                return acc;
            }, []);

            const inProgressExecutions = [...runningWorkflow, ...runningTasks, ...queuedWorkflow, ...queuedTasks];
            if (inProgressExecutions.length === 0) {
                runningExecution.value = null;
                otherRunningExecutions.value = [];
                return;
            }

            if (
                inProgressExecutions.length > 1 &&
                !R.isNil(runningExecution.value) &&
                !R.isNil(runningExecution.value.executionId) &&
                R.pluck('executionId')(inProgressExecutions).includes(runningExecution.value.executionId)
            ) {
                const taskIndex = R.findIndex(R.propEq('executionId', runningExecution.value.executionId))(
                    inProgressExecutions,
                );

                runningExecution.value = {
                    task: S.has('task', inProgressExecutions[taskIndex]) ? inProgressExecutions[taskIndex].task : null,
                    executionId: inProgressExecutions[taskIndex].executions[0].id,
                    type: inProgressExecutions[taskIndex].executions[0].type,
                    status: inProgressExecutions[taskIndex].executions[0].status,
                    createdAt: new Date(inProgressExecutions[taskIndex].executions[0].createdAt),
                };
            } else if (inProgressExecutions.length > 0) {
                [runningExecution.value] = inProgressExecutions;
            }

            otherRunningExecutions.value = inProgressExecutions.filter(
                (execution: RunningExecution) =>
                    !R.isNil(runningExecution.value) && execution.executionId !== runningExecution.value.executionId,
            );
        }
    };

    const loading = computed(() => axiosRunner.loading.value);
    const errors = computed(() => (axiosRunner.error.value ? [axiosRunner.error] : []));

    const taskVisualisations = computed(() =>
        visualisations.value
            ? visualisations.value.reduce((acc: any, visualisation: { id: string; taskId: string }) => {
                  acc[visualisation.taskId] = visualisation.id;
                  return acc;
              }, {})
            : {},
    );

    const createTask = (taskPayload: any) =>
        axiosRunner
            .exec(WorkflowAPI.createTask(taskPayload))
            .catch((e: { response: { status: any; data: { message: string } } }) => {
                if (e.response && e.response?.status === 403 && e.response?.data?.message === 'Locked') {
                    (root as any).$toastr.e('The analytics pipeline is locked by another user', 'Error');
                }
            });

    const updateTask = (task: Task): Promise<any> =>
        new Promise((resolve, reject) => {
            axiosRunner
                .exec(TaskAPI.update(task))
                .then((res: any) => resolve(res.data))
                .catch((e: any) => reject(e));
        });

    const deleteTask = (task: Task) => axiosRunner.exec(TaskAPI.delete(task.id)).catch();

    const deleteDisabledTasks = () => axiosRunner.exec(WorkflowAPI.deleteDisabledTasks(workflow.value.id)).catch();

    const finaliseWorkflow = async () => {
        return new Promise<void>((resolve, reject) => {
            axiosRunner
                .exec(WorkflowAPI.finalise(workflow.value.id))
                .then(() => {
                    if (axiosRunner.error.value) {
                        reject(axiosRunner.error.value);
                    } else {
                        resolve();
                    }
                    refetchWorkflow();
                })
                .catch(reject);
        });
    };

    const unlockWorkflow = async () => {
        await axiosRunner
            .exec(WorkflowAPI.unlock(workflowId))
            .then(() => {
                refetchWorkflow();
            })
            .catch((e: { response: { status: any; data: { message: string } } }) => {
                if (e.response && e.response?.status === 403 && e.response?.data?.message === 'Locked') {
                    (root as any).$toastr.e('The analytics pipeline is locked by another user', 'Error');
                }
            });
    };

    const upgradeWorkflow = async () => {
        try {
            await axiosRunner.exec(WorkflowAPI.upgrade(workflowId));
            await refetchWorkflow();
            showUpgradeConfirmation.value = false;
        } catch {
            (root as any).$toastr.e('Error upgrading analytics pipeline', 'Error');
        }
    };

    const upgradeWorkflowDry = async () => {
        await axiosRunner
            .exec(WorkflowAPI.upgradeDry(workflowId))
            .then((res: any) => {
                blocksNotSupportedInNewVersion.value = res.data.removedTaskBlocks;
                blocksUpgradedInNewVersion.value = res.data.upgradedTaskBlocks;
                showUpgradeConfirmation.value = true;
            })
            .catch(() => {
                (root as any).$toastr.e('Error upgrading analytics pipeline', 'Error');
            });
    };

    const canUpgradeFrameworkVersion = computed(() => {
        const version = store.state.executionVersions.analyticsExecutionVersion;
        return version &&
            version !== 'latest' &&
            workflow.value &&
            [WorkflowStatus.Draft, WorkflowStatus.Updating].includes(workflow.value.status) &&
            workflow.value.imageVersion !== 'latest'
            ? compareVersions(workflow.value.imageVersion, version) < 0
            : false;
    });

    const isLoopTask = (task: Task) => loops.value.some((loop: Loop) => loop.startFor === task.id);

    const processTasks = async (unsortedTasks: Task[], concurrentUserIn: boolean = false) => {
        loops.value = R.clone(R.pathOr([], ['configuration', 'loops'], workflow.value));

        // calculate loops to be drawned on DAG
        dagLoops.value = [];
        for (let i = 0; i < loops.value.length; i++) {
            const loop = loops.value[i];
            const task: Task | undefined = unsortedTasks.find((t: any) => t.id === loop.startFor);
            if (task) {
                const loopConfig = {
                    id: `loop-${loop.startFor}`,
                    name: task.displayName,
                    tasks: loop.tasks,
                    bgColour: '#c3dafe', // static background colour for all for loops
                    lastTask: loop.lastTask,
                };
                dagLoops.value.push(loopConfig);
            }
        }
        taskMap.value = new Map<string, Task>();
        if (!R.isNil(unsortedTasks)) {
            for (let t = 0; t < unsortedTasks.length; t++) {
                const task: Task = unsortedTasks[t];
                if (
                    task.executions.length > 0 &&
                    Object.keys(executionLogsPerExecution.value).includes(task.executions[0].id)
                )
                    task.executions[0].logs = executionLogsPerExecution.value[task.executions[0].id];
                taskMap.value.set(task.id, task);
            }

            const topoSort = new TopologicalSort(taskMap.value);
            for (let t = 0; t < unsortedTasks.length; t++) {
                const task: Task = unsortedTasks[t] as Task;
                for (let e = 0; e < task.downstreamTaskIds.length; e++) {
                    const edge = task.downstreamTaskIds[e];
                    topoSort.addEdge(task.id, edge);
                }
            }
            const sorted = topoSort.sort();
            const sortedKeys = [...sorted.keys()];
            const sortedTasks: Task[] = [];
            for (let s = 0; s < sortedKeys.length; s++) {
                const taskId = sortedKeys[s];
                if (taskMap.value.has(taskId)) {
                    sortedTasks.push(taskMap.value.get(taskId) as Task);
                }
            }
        }

        // only fetch results if pipeline is finalized
        // only used to calculate if pipeline can be reoppened
        if (isFinalised.value)
            axiosRunner
                .exec(WorkflowAPI.getResults(workflow.value.id))
                .then((response: any) => {
                    resultAssets.value = response.data;
                })
                .catch();

        setCurrentlyRunningExecution();

        if (!concurrentUserIn) await runValidation();

        // Reset failed and blocked executions
        failedExecutions.value = [];
        blockedExecutions.value = [];

        // Calculate tasks that need to do a test run
        pendingExecutions.value = pendingExecutions.value.filter(
            (execution: { type: ExecutionType; task?: Task | null }) => execution.type !== ExecutionType.Dry,
        );

        workflowDags.value.forEach((dag: string[]) => {
            let cannotRunTaskIds: string[] = [];
            dag.forEach(async (taskId: string) => {
                if (taskMap.value.has(taskId)) {
                    const task: Task = taskMap.value.get(taskId) as Task;
                    const category = BlockCategoryWrapper.find(task.block.category);
                    if (task.executions.length > 0 && task.executions[0].status === ExecutionStatus.Failed) {
                        failedExecutions.value.push({
                            type: ExecutionType.Dry,
                            task,
                            blocker: !!task.downstreamTaskIds.length,
                        });
                        // Add the current task and all of its downstream tasks to the 'cannot run' list (unique)
                        cannotRunTaskIds = [...new Set([...cannotRunTaskIds, task.id, ...task.downstreamTaskIds])];
                    } else if (
                        (category?.canDryRun || task.block.type === BlockType.Apply) &&
                        !isLoopTask(task) &&
                        task.executions.length === 0 &&
                        !blockedTaskIds.value.includes(task.id)
                    ) {
                        if (!cannotRunTaskIds.includes(task.id)) {
                            await queueExecution(ExecutionType.Dry, task);
                        } else {
                            cannotRunTaskIds = [...new Set([...cannotRunTaskIds, ...task.downstreamTaskIds])];
                            blockedExecutions.value.push({ type: ExecutionType.Dry, task });
                        }
                    }
                }
            });
        });
    };

    const processWorkflow = async (workflowRes: any, concurrentUserIn: boolean = false) => {
        if (!R.isNil(workflowRes)) {
            const unsortedTasks: Task[] = workflowRes.tasks;

            workflow.value = R.pick(
                [
                    'id',
                    'name',
                    'description',
                    'type',
                    'framework',
                    'platform',
                    'runner',
                    'status',
                    'configuration',
                    'createdAt',
                    'updatedAt',
                    'executions',
                    'createdBy',
                    'imageVersion',
                    'inputAssetIds',
                ],
                workflowRes,
            );
            visualisations.value = workflowRes.visualisations;
            pipelines.value = R.clone(workflow.value.configuration.pipelines);

            await processTasks(unsortedTasks, concurrentUserIn);
        }
    };
    /**
     * The function is in charge of fetching the optimsed version of the workflow.
     * It also does several other things:
     * - Extracts visualisations
     * - Extracts pipelines
     * - Extracts loops
     * - Extracts schedules
     * - Places tasks in a task map so that they can be easily accessed by id
     * - Sorts the task using toposort in order to appear in a sensible order linearly
     * - Calculates the dags to be displayed in the UI
     * - Fetches the result assets
     * - Sets the currently running execution
     * - Triggers a workflow validation
     * - Figures out pending executions to be displayed
     * - Marks invalid tasks in the dags
     * Param: concurrentUserIn
     * returns promise including the workflow response
     *
     * Explanation of the concurrentUserIn param:
     * This is for when more than 2 users are in the Workflow Designer page of the same workflow.
     * All users who got the message that the workflow is locked, we also pass the is locked param here
     * as concurrentUserIn. This param is passed as true only in the case of refetching a workflow
     * after the message of the WebSockets that an execution is completed is retrieved in order
     * not to run the validation again and display the toastr that this workflow is locked for the 2nd time.
     */
    const refetchWorkflow = (concurrentUserIn: boolean = false): Promise<any> => {
        return new Promise((resolve, reject) => {
            axiosRunner
                .exec(WorkflowAPI.getWorkflowOptimised(workflowId))
                .then(async (res: any) => {
                    if (res.data?.type !== 'analytics') {
                        (root as any).$toastr.e('The analytics pipeline requested does not exist', 'Not Found');
                        root.$router.push({ name: 'workflows', query: JSON.parse(queryParams) }).catch(() => null);
                        reject();
                    } else {
                        const workflowRes = res.data;

                        resolve(await processWorkflow(workflowRes, concurrentUserIn));
                    }
                })
                .catch((e) => {
                    if (e.response) {
                        switch (e.response.status) {
                            case 403:
                                (root as any).$toastr.e('You do not have access to the specific pipeline', 'Error');
                                break;
                            case 404:
                                (root as any).$toastr.e('The Analytics Pipeline was not found', 'Error');
                                break;
                        }
                        root.$router.push({ name: 'workflows' }).catch(() => null);
                    }
                    reject(e);
                });
        });
    };

    const tasks: Ref<Task[]> = computed(() => {
        const unsortedTasks = Array.from(taskMap.value.values());
        if (unsortedTasks.length === 0) return [];
        const topoSort = new TopologicalSort(taskMap.value);
        for (let t = 0; t < unsortedTasks.length; t++) {
            const task: Task = unsortedTasks[t] as Task;
            for (let e = 0; e < task.downstreamTaskIds.length; e++) {
                const edge = task.downstreamTaskIds[e];
                topoSort.addEdge(task.id, edge);
            }
        }
        const sorted = topoSort.sort();
        const sortedKeys = [...sorted.keys()];
        const sortedTasks: Task[] = [];
        for (let s = 0; s < sortedKeys.length; s++) {
            const taskId = sortedKeys[s];
            if (taskMap.value.has(taskId)) {
                sortedTasks.push(taskMap.value.get(taskId) as Task);
            }
        }

        return sortedTasks;
    });

    refetchWorkflow();

    const onMessage = async (data: EventMessage) => {
        // Handle the case where we have an execution status change
        if (
            data.type === MessageType.Status &&
            (R.isNil(data.body.taskId) ||
                (taskMap.value.has(data.body.taskId) && !R.isNil(taskMap.value.get(data.body.taskId)))) &&
            Object.values(ExecutionType).includes(data.body.executionType as ExecutionType)
        ) {
            const executedTask = data.body.taskId ? taskMap.value.get(data.body.taskId) : null;
            const executionType = data.body.executionType ? ExecutionTypeWrapper.find(data.body.executionType) : null;

            if (!R.isNil(executionType) && !R.isNil(data.body.status)) {
                const { message, category } = executionType.message(data.body.status, executedTask, data.body.message);
                if (data.body.status === ExecutionStatus.Cancelled) {
                    runningExecution.value = null;
                    (root as any).$toastr.w(message, category);
                } else if (data.body.status === ExecutionStatus.Failed) {
                    (root as any).$toastr.e(message, category);
                }
                // for running executions of other users, not the one who submitted it
                else if (
                    R.isNil(runningExecution.value) &&
                    data.body.status === ExecutionStatus.Running &&
                    isLocked.value
                ) {
                    // fill in the current running execution
                    runningExecution.value = {
                        task: taskMap.value.get(data.body.taskId as string),
                        type: data.body.executionType as ExecutionType,
                        status: ExecutionStatus.Running,
                        executionId: data.executionId,
                        createdAt: data.body?.timestamp,
                    };
                }

                // if we have a successful training then refetch models
                if (
                    data.body.status === ExecutionStatus.Completed &&
                    executedTask?.block.category === BlockCategory.MachineLearning &&
                    executedTask.block.type === BlockType.Train
                )
                    await refetchModels();
            }

            // Figure out if the currently running execution has completed
            if (!R.isNil(runningExecution.value) && !R.isNil(data.body.status)) {
                if (
                    data.executionId === runningExecution.value.executionId &&
                    ExecutionStatusWrapper.finishedStatuses().includes(data.body.status)
                ) {
                    // If execution has completed (succesfully or otherwise) clear running execution
                    // and refetch task data and models
                    // TODO: Test if any conflicts
                    // runningExecution.value = null;
                    await refetchWorkflow(isLocked.value).then(async () => {
                        refetchModels();
                        refreshExecutionLogs();
                    });
                } else {
                    runningExecution.value = { ...runningExecution.value, status: data.body.status };
                }
            }

            // In case there are no running executions but there are pending ones
            // then call the next pending one
            if (
                R.isNil(runningExecution.value) &&
                pendingExecutions.value.length > 0 &&
                !R.isNil(pendingExecutions.value[0].task)
            ) {
                const executionToBeRun = pendingExecutions.value.shift();
                if (executionToBeRun && executionToBeRun.task)
                    runExecution(executionToBeRun.type, executionToBeRun.task);
            }
        }
        if (!R.isNil(data.body.message)) {
            newLogMessageArrived.value = {
                message: data.body.message,
                timestamp: data.body.timestamp,
                level: data.body.level as string,
                executionId: data.executionId,
                taskId: undefined,
            };
        } else {
            newLogMessageArrived.value = null;
        }
    };

    const lastExecution = computed(() => {
        let last: any = null;
        taskMap.value.forEach((task: Task) => {
            if (!last || (task.executions.length && task.executions[0].createdAt > last.createdAt)) {
                last = task.executions[0];
                if (last) last['blockName'] = task.displayName;
            }
        });
        return last;
    });

    // list of active execution ids
    const activeExecutionIds = computed((): string[] => {
        if (R.isNil(tasks.value)) return [];
        const ids = tasks.value.reduce((acc: string[], task: Task) => {
            if (task.executions?.length) acc.push(task.executions[0].id);
            return acc;
        }, []);
        if (runningExecution.value?.executionId) ids.push(runningExecution.value.executionId);
        ids.sort();
        return ids;
    });

    const { refresh: refreshExecutionLogs } = useExecutionLogs(activeExecutionIds);
    return {
        workflow,
        tasks,
        taskMap,
        loading,
        runningExecution,
        pendingExecutions,
        failedExecutions,
        blockedExecutions,
        otherRunningExecutions,
        validationErrors,
        invalidTaskIds,
        blockedTaskIds,
        pipelines,
        dagLoops,
        loops,
        visualisations,
        taskVisualisations,
        isFinalised,
        isDeprecated,
        canBeReopened,
        isLocked,
        errors,
        refetchWorkflow,
        createTask,
        updateTask,
        deleteTask,
        runValidation,
        unlockWorkflow,
        queueExecution,
        finaliseWorkflow,
        deleteDisabledTasks,
        canUpgradeFrameworkVersion,
        showUpgradeConfirmation,
        upgradeWorkflowDry,
        upgradeWorkflow,
        onMessage,
        blocksNotSupportedInNewVersion,
        blocksUpgradedInNewVersion,
        latestExecution: lastExecution,
        refreshExecutionLogs,
        newLogMessageArrived,
        processTasks,
    };
}
