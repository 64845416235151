var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.totalAssets > 0)?_c('parameter-validation',{staticClass:"flex flex-col w-full",attrs:{"rules":_vm.rules,"name":_vm.parameter.type,"changeDate":_vm.changeDate,"strict":_vm.strict},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var errorTextColour = ref.errorTextColour;
var failedRules = ref.failedRules;
return [_c('AdvancedSelect',{staticClass:"w-full",attrs:{"items":_vm.assets,"keyField":"id","labelField":"name","multiple":_vm.parameter.multiple,"errorColour":errorTextColour,"errors":errors,"failedRules":failedRules,"validationMessages":_vm.validationMessages,"disabled":_vm.readonly,"expandInPlace":"","itemsAreFiltered":"","noOfItems":_vm.noOfItems,"minCharacters":3,"searchPlaceholder":"Type at least 3 characters...","totalItems":_vm.totalItems,"selectedItems":_vm.selectedItems,"autoSearch":false},on:{"change":_vm.change,"search":_vm.refetch},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var fullItem = ref.fullItem;
return [_c('div',{staticClass:"flex flex-row items-center space-x-2 group"},[_c('div',{staticClass:"w-4 h-4"},[(fullItem.parquetStatus === 'optimised')?_c('LightningBoltIcon',{staticClass:"w-4 h-4 text-yellow-600 group-hover:text-yellow-400"}):_vm._e()],1),_c('span',[_vm._v(_vm._s(fullItem.name))])])]}},{key:"tooltip",fn:function(ref){
var fullItem = ref.fullItem;
return [_c('div',{staticClass:"flex flex-row py-1 space-x-2"},[_c('span',[_vm._v(_vm._s(fullItem.name))]),(fullItem.parquetStatus === 'optimised')?_c('span',{staticClass:"text-yellow-600"},[_vm._v(" Optimised for Analytics")]):_vm._e()])]}}],null,true),model:{value:(_vm.currentValue),callback:function ($$v) {_vm.currentValue=$$v},expression:"currentValue"}}),_c('input',{attrs:{"type":"hidden","name":_vm.name},domProps:{"value":_vm.currentValue}})]}}],null,false,3034608260)}):_c('div',{staticClass:"text-xs text-neutral-600"},[_vm._v("No dataset assets available")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }